import React, { useState, useEffect } from 'react';
import '../css/Admin.css';

const AdminProducts = () => {
    const [categories, setCategories] = useState([]);
    const [productsByCategory, setProductsByCategory] = useState({});
    const [editingProduct, setEditingProduct] = useState(null);
    const [editedFields, setEditedFields] = useState({});
    const [error, setError] = useState('');

    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoriesRes = await fetch('http://localhost:5000/api/categories', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const categoriesData = await categoriesRes.json();
                setCategories(categoriesData);

                const productsRes = await fetch('http://localhost:5000/api/products', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const productsData = await productsRes.json();

                // Grouper les produits par catégorie
                const groupedProducts = productsData.reduce((acc, product) => {
                    acc[product.category_id] = acc[product.category_id] || [];
                    acc[product.category_id].push(product);
                    return acc;
                }, {});
                setProductsByCategory(groupedProducts);
            } catch (err) {
                setError('Erreur lors du chargement des données.');
            }
        };
        fetchData();
    }, [token]);

    const startEditing = (product) => {
        setEditingProduct(product.id);
        setEditedFields({
            name: product.name,
            price: product.price,
            stock: product.stock,
        });
    };

    const handleFieldChange = (field, value) => {
        setEditedFields((prev) => ({ ...prev, [field]: value }));
    };

    const saveChanges = async () => {
        if (!editingProduct) return;

        try {
            const response = await fetch(`http://localhost:5000/api/products/${editingProduct}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(editedFields),
            });
            const data = await response.json();

            if (response.ok && data.success) {
                alert('Modifications sauvegardées avec succès.');
                setEditingProduct(null);
                setEditedFields({});
            } else {
                alert(data.message || 'Erreur lors de la sauvegarde.');
            }
        } catch (err) {
            alert('Erreur serveur lors de la sauvegarde.');
        }
    };

    return (
        <div className="container">
            <h1>Gestion des produits</h1>
            {error && <p className="error">{error}</p>}

            {categories.map((category) => (
                <div key={category.id} className="category-section">
                    <h2>{category.name}</h2>
                    <ul>
                        {productsByCategory[category.id]?.map((product) => (
                            <li key={product.id} className="product-item">
                                {editingProduct === product.id ? (
                                    <div className="edit-product">
                                        <input
                                            type="text"
                                            value={editedFields.name}
                                            onChange={(e) =>
                                                handleFieldChange('name', e.target.value)
                                            }
                                            placeholder="Nom du produit"
                                        />
                                        <input
                                            type="number"
                                            value={editedFields.price}
                                            onChange={(e) =>
                                                handleFieldChange('price', e.target.value)
                                            }
                                            placeholder="Prix"
                                        />
                                        <input
                                            type="number"
                                            value={editedFields.stock}
                                            onChange={(e) =>
                                                handleFieldChange('stock', e.target.value)
                                            }
                                            placeholder="Stock"
                                        />
                                        <button onClick={saveChanges}>Sauvegarder</button>
                                        <button onClick={() => setEditingProduct(null)}>
                                            Annuler
                                        </button>
                                    </div>
                                ) : (
                                    <div className="product-details">
                                        <strong>{product.name}</strong> - {product.price}€
                                        <span>Stock : {product.stock}</span>
                                        <button onClick={() => startEditing(product)}>
                                            Modifier
                                        </button>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default AdminProducts;
