import React, { useState } from 'react';
import AdminClients from './AdminClients';
import AdminOrders from './AdminOrders';
import AdminProducts from './AdminProducts';
import '../css/Admin.css';

const Admin = () => {
    const [activeTab, setActiveTab] = useState('clients'); // Onglet actif par défaut

    return (
        <div className="admin-container">
            {/* Navigation par onglets */}
            <div className="admin-tabs">
                <button
                    className={`admin-tab ${activeTab === 'clients' ? 'active' : ''}`}
                    onClick={() => setActiveTab('clients')}
                >
                    Clients
                </button>
                <button
                    className={`admin-tab ${activeTab === 'orders' ? 'active' : ''}`}
                    onClick={() => setActiveTab('orders')}
                >
                    Commandes
                </button>
                <button
                    className={`admin-tab ${activeTab === 'products' ? 'active' : ''}`}
                    onClick={() => setActiveTab('products')}
                >
                    Produits
                </button>
            </div>

            {/* Contenu affiché selon l'onglet actif */}
            <div className="admin-content">
                {activeTab === 'clients' && <AdminClients />}
                {activeTab === 'orders' && <AdminOrders />}
                {activeTab === 'products' && <AdminProducts />}
            </div>
        </div>
    );
};

export default Admin;
