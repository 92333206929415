import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Market from './pages/Market';
import Cart from './pages/Cart';
import Admin from './pages/Admin'; 
import Orders from './pages/Orders';
import Clients from './pages/Clients';
import Visits from './pages/Visits';
import Stats from './pages/Stats';


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route
                    path="/*"
                    element={
                        <>
                       
                            <Routes>
                                <Route path="/market" element={<Market />} />
                                <Route path="/cart" element={<Cart />} />
                                <Route path="/admin" element={<Admin />} />
                                <Route path="/orders" element={<Orders />} />
                                <Route path="/clients" element={<Clients />} />
                                <Route path="/visits" element={<Visits />} />
                                <Route path="/stats" element={<Stats />} />
                            </Routes>
                        </>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
