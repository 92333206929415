import React, { useState, useEffect } from 'react';
import '../css/Clients.css';

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [editingClient, setEditingClient] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null); // Client sélectionné pour le popup
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetch('http://localhost:5000/api/clients', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                console.log('Clients reçus :', data); // Vérifiez ici les données
                setClients(data);
            })
            .catch((err) => console.error('Erreur lors de la récupération des clients :', err));
    }, [token]);
    

    const startEditing = (client) => {
        setEditingClient(client.id);
        setName(client.name);
        setEmail(client.email);
        setPhone(client.phone);
        setAddress(client.address);
    };

    const updateClient = () => {
        fetch(`http://localhost:5000/api/clients/${editingClient}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name, email, phone, address }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setClients((prev) =>
                        prev.map((client) =>
                            client.id === editingClient ? { ...client, name, email, phone, address } : client
                        )
                    );
                    setEditingClient(null);
                    setName('');
                    setEmail('');
                    setPhone('');
                    setAddress('');
                } else {
                    alert('Erreur lors de la mise à jour du client');
                }
            })
            .catch((err) => console.error('Erreur lors de la mise à jour :', err));
    };

    const createClient = () => {
        if (!name || !email || !phone || !address) {
            alert('Veuillez remplir tous les champs pour créer un client.');
            return;
        }

        fetch('http://localhost:5000/api/clients', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name, email, phone, address }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setClients([...clients, { id: data.clientId, name, email, phone, address }]);
                    setName('');
                    setEmail('');
                    setPhone('');
                    setAddress('');
                    setIsCreating(false);
                } else {
                    alert('Erreur lors de la création du client');
                }
            })
            .catch((err) => console.error('Erreur lors de la création du client :', err));
    };

    return (
        <div>
            <h1>Clients</h1>

            {/* Formulaire pour créer un nouveau client */}
            {isCreating ? (
                <div>
                    <h2>Créer un nouveau client</h2>
                    <input
                        type="text"
                        placeholder="Nom"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Téléphone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Adresse"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <button onClick={createClient}>Créer</button>
                    <button onClick={() => setIsCreating(false)}>Annuler</button>
                </div>
            ) : (
                <button onClick={() => setIsCreating(true)}>Ajouter un client</button>
            )}

            {/* Liste des clients */}
            <ul>
                {clients.map((client) => (
                    <li key={client.id}>
                        {editingClient === client.id ? (
                            <div>
                                <input
                                    type="text"
                                    placeholder="Nom"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Téléphone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Adresse"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <button onClick={updateClient}>Sauvegarder</button>
                                <button onClick={() => setEditingClient(null)}>Annuler</button>
                            </div>
                        ) : (
                            <div>
                                <span
                                    style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                                    onClick={() => setSelectedClient(client)}
                                >
                                    {client.name}
                                </span>
                                <button onClick={() => startEditing(client)}>Modifier</button>
                            </div>
                        )}
                    </li>
                ))}
            </ul>

            {/* Popup pour afficher les détails du client */}
{selectedClient && (
    <div className="popup">
        <div className="popup-content">
            <h2>Détails du client</h2>
            <p><strong>Nom :</strong> {selectedClient.name}</p>
            <p><strong>Email :</strong> {selectedClient.email}</p>
            <p><strong>Téléphone :</strong> {selectedClient.phone}</p>
            <p><strong>Adresse :</strong> {selectedClient.address}</p>
            <button onClick={() => setSelectedClient(null)}>Fermer</button>
        </div>
    </div>
)}

        </div>
    );
};

export default Clients;
