import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('http://localhost:5000/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (data.success) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('userId', data.userId);
                navigate('/market');
            } else {
                setErrorMessage('Compte introuvable');
                setTimeout(() => setErrorMessage(''), 3000); // Efface après 3 secondes
            }
        } catch (error) {
            console.error('Erreur lors de la connexion', error);
            setErrorMessage('Erreur lors de la connexion');
            setTimeout(() => setErrorMessage(''), 3000); // Efface après 3 secondes
        }
    };

    return (
        <div
            className="login-container"
            style={{
                backgroundImage: "url('/login-back.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
            }}
        >
            <div className="login-card">
                <h2 className="login-title">Connexion</h2>
                <form onSubmit={handleLogin} className="login-form">
                    <div className="input-group">
                        <i className="fas fa-envelope input-icon"></i>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <i className="fas fa-lock input-icon"></i>
                        <input
                            type="password"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Connexion</button>
                </form>
                {errorMessage && <div className="error-toast">{errorMessage}</div>}
                <a href="/" className="forgot-password">Mot de passe oublié ?</a>
            </div>
        </div>
    );
};

export default Login;
