import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Market.css";
import "../css/Popup.css";

const Market = () => {
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [productPopupVisible, setProductPopupVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1); // Quantité locale dans le popup


    // Charger les produits et clients
    useEffect(() => {
        const fetchData = async () => {
            try {
                const productsRes = await fetch("http://localhost:5000/api/products");
                const clientsRes = await fetch("http://localhost:5000/api/clients", {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const productsData = await productsRes.json();
                const clientsData = await clientsRes.json();

                setProducts(productsData);
                setClients(clientsData);
            } catch (err) {
                console.error("Erreur lors du chargement des données:", err);
            }
        };

        fetchData();
    }, [token]);

    const handleAddToCart = async (productId, quantity = 1) => {
        if (!selectedClient) {
            alert("Veuillez sélectionner un client avant d’ajouter un produit au panier.");
            return;
        }

        try {
            const response = await fetch("http://localhost:5000/api/cart/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    productId,
                    quantity,
                }),
            });

            const data = await response.json();
            if (data.success) {
                alert("Produit ajouté au panier avec succès !");
                setProductPopupVisible(false); // Fermer le popup après ajout
            } else {
                alert("Erreur lors de l’ajout au panier.");
            }
        } catch (error) {
            console.error("Erreur lors de l’ajout au panier:", error);
        }
    };

    const handleClientSelect = (client) => {
        setSelectedClient(client);
        setPopupVisible(false);
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setProductPopupVisible(true);
    };

    return (
        <div className="market-container">
            {/* Header avec utilisateur sélectionné */}
            <div className="market-header">
                <button className="select-client-btn" onClick={() => setPopupVisible(true)}>
                    {selectedClient ? "Changer de client" : "Choisir un client"}
                </button>
            </div>

            {/* Liste des produits */}
            <div className="market-products">
                {products.map((product) => (
                    <div
                        className="product-card"
                        key={product.id}
                        onClick={() => handleProductClick(product)}
                    >
                        <div className="product-card-header">
                            <img
                                src={product.image}
                                alt={product.name}
                                onError={(e) => (e.target.src = "/placeholder.png")}
                                className="product-image"
                            />
                            <button className="add-to-cart-btn" onClick={() => handleAddToCart(product.id)}>
                                +
                            </button>
                        </div>
                        <div className="product-card-body">
                            <h4 className="product-name">{product.name}</h4>
                            <p className="product-price">
                                € {product.price ? parseFloat(product.price).toFixed(2) : "0.00"}
                            </p>
                            <p className="product-details">
                                {product.unitType || "Pièce"} - #{product.id}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Popup pour choisir un client */}
            {popupVisible && (
                <div className="popup-overlay">
                    <div className="popup-container">
                        <div className="popup-header">
                            <h3>Choisissez un client</h3>
                            <button className="close-popup" onClick={() => setPopupVisible(false)}>
                                ✖
                            </button>
                        </div>
                        <div className="popup-body">
                            <input
                                type="text"
                                placeholder="Rechercher un client..."
                                className="client-search-input"
                            />
                            <ul className="client-list">
                                {clients.map((client) => (
                                    <li
                                        key={client.id}
                                        className={`client-item ${
                                            selectedClient?.id === client.id ? "selected" : ""
                                        }`}
                                        onClick={() => handleClientSelect(client)}
                                    >
                                        <span className="client-name">{client.name}</span>
                                        <span className="client-email">{client.email}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            {/* Popup pour les détails du produit */}
            {selectedProduct && (
    <div className="popup-overlay">
        <div className="popup-container">
            <div className="popup-header">
                <h3>{selectedProduct.name}</h3>
                <button className="close-popup" onClick={() => setSelectedProduct(null)}>
                    ✖
                </button>
            </div>
            <div className="popup-body">
                <img src={selectedProduct.image} alt={selectedProduct.name} />
                <p>Prix : € {parseFloat(selectedProduct.price).toFixed(2)}</p>
                <p>Stock : {selectedProduct.stock}</p>
                <div className="quantity-controls">
                    <button onClick={() => setQuantity((prev) => (prev > 1 ? prev - 1 : 1))}>-</button>
                    <span>{quantity}</span>
                    <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
                </div>
            </div>
            <div className="popup-footer">
                <button className="cancel-btn" onClick={() => setSelectedProduct(null)}>
                    Annuler
                </button>
                <button
                    className="add-btn"
                    onClick={() => handleAddToCart(selectedProduct.id, quantity)}
                >
                    Ajouter au panier
                </button>
            </div>
        </div>
    </div>
)}


            {/* Barre de navigation */}
            <div className="bottom-navigation">
                <div
                    className={`nav-item ${window.location.pathname === "/market" ? "active" : ""}`}
                    onClick={() => navigate("/market")}
                >
                    <i className="fas fa-store"></i>
                    <span>Market</span>
                </div>
                <div
                    className={`nav-item ${window.location.pathname === "/cart" ? "active" : ""}`}
                    onClick={() => navigate("/cart", { state: { client: selectedClient } })}
                >
                    <i className="fas fa-shopping-cart"></i>
                    <span>Panier</span>
                </div>
                <div
                    className={`nav-item ${window.location.pathname === "/clients" ? "active" : ""}`}
                    onClick={() => navigate("/clients")}
                >
                    <i className="fas fa-users"></i>
                    <span>Clients</span>
                </div>
            </div>
        </div>
    );
};

export default Market;
