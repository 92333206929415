import React, { useState, useEffect } from 'react';

const Visits = () => {
    const [clients, setClients] = useState([]);
    const [visits, setVisits] = useState([]);
    const [selectedDay, setSelectedDay] = useState('lundi');
    const [selectedClient, setSelectedClient] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        // Charger les clients
        fetch('http://localhost:5000/api/clients', {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((data) => setClients(data));

        // Charger les visites pour le jour sélectionné
        fetch(`http://localhost:5000/api/visits/${selectedDay}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((data) => setVisits(data));
    }, [selectedDay, token]);

    const addVisit = () => {
        fetch('http://localhost:5000/api/visits', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ clientId: selectedClient, visitDay: selectedDay }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setVisits([...visits, { id: data.visitId, ...clients.find(c => c.id === selectedClient) }]);
                }
            });
    };

    const removeVisit = (id) => {
        fetch(`http://localhost:5000/api/visits/${id}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then(() => setVisits(visits.filter((visit) => visit.id !== id)));
    };

    return (
        <div>
            <h1>Visites du jour : {selectedDay}</h1>
            <select onChange={(e) => setSelectedDay(e.target.value)} value={selectedDay}>
                <option value="lundi">Lundi</option>
                <option value="mardi">Mardi</option>
                <option value="mercredi">Mercredi</option>
                <option value="jeudi">Jeudi</option>
                <option value="vendredi">Vendredi</option>
                <option value="samedi">Samedi</option>
                <option value="dimanche">Dimanche</option>
            </select>
            <h2>Ajouter une visite</h2>
            <select onChange={(e) => setSelectedClient(e.target.value)} value={selectedClient}>
                <option value="">Sélectionner un client</option>
                {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                        {client.name}
                    </option>
                ))}
            </select>
            <button onClick={addVisit}>Ajouter</button>
            <h2>Clients à visiter</h2>
            <ul>
                {visits.map((visit) => (
                    <li key={visit.id}>
                        {visit.name} - {visit.phone}
                        <button onClick={() => removeVisit(visit.id)}>Supprimer</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Visits;
