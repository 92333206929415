import React, { useState, useEffect } from 'react';
import '../css/Admin.css';

const AdminOrders = () => {
    const [orders, setOrders] = useState([]);
    const [expandedOrder, setExpandedOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const token = localStorage.getItem('token'); // Récupérer le token JWT

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch('http://localhost:5000/api/orders', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des commandes');
                }

                const data = await response.json();

                // Trier les commandes pour que la plus récente soit en haut
                const sortedOrders = data.sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                );

                // Grouper les commandes par numéro de commande
                const groupedOrders = sortedOrders.reduce((acc, order) => {
                    acc[order.orderId] = acc[order.orderId] || [];
                    acc[order.orderId].push(order);
                    return acc;
                }, {});

                setOrders(groupedOrders);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchOrders();
    }, [token]);

    const toggleExpandOrder = (orderId) => {
        setExpandedOrder(expandedOrder === orderId ? null : orderId);
    };

    const handleSaveChanges = (orderId) => {
        alert(`Modifications pour la commande ${orderId} sauvegardées !`);
        // Ajouter la logique de sauvegarde ici
    };

    if (loading) {
        return <div>Chargement des commandes...</div>;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <div className="container">
            <h1>Commandes</h1>
            {Object.keys(orders).map((orderId) => (
                <div key={orderId} className="order-group">
                    <div className="order-header" onClick={() => toggleExpandOrder(orderId)}>
                        <div>
                            <h3>Commande n°{orderId}</h3>
                            <p>
                                Client :{' '}
                                {orders[orderId][0]?.userEmail || 'Non spécifié'}
                            </p>
                        </div>
                        <span>
                            {expandedOrder === orderId ? '▼' : '▶'} Détails
                        </span>
                    </div>
                    {expandedOrder === orderId && (
                        <div className="order-details">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Produit</th>
                                        <th>Quantité</th>
                                        <th>Prix</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders[orderId].map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.productName}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    defaultValue={item.quantity}
                                                    style={{ width: '50px' }}
                                                />
                                            </td>
                                            <td>
    <input
        type="number"
        step="0.01"
        defaultValue={parseFloat(item.price || 0).toFixed(2)}
        style={{ width: '70px' }}
    />
</td>
<td>
    {(item.quantity * parseFloat(item.price || 0)).toFixed(2)} €
</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button
                                className="save-order-btn"
                                onClick={() => handleSaveChanges(orderId)}
                            >
                                Sauvegarder
                            </button>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AdminOrders;
