import React, { useEffect, useState } from 'react';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState('');
    const userId = localStorage.getItem('userId'); // Récupération de l'ID utilisateur depuis localStorage
    const token = localStorage.getItem('token'); // Récupération du token JWT depuis localStorage

    useEffect(() => {
        if (!userId || !token) {
            setError('Vous devez être connecté pour voir vos commandes.');
            return;
        }

        fetch(`http://localhost:5000/api/orders/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Ajout du token JWT dans l'en-tête
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Erreur lors de la récupération des commandes');
                }
                return res.json();
            })
            .then((data) => setOrders(data))
            .catch((err) => setError(err.message));
    }, [userId, token]);

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <div>
            <h1>Historique des commandes</h1>
            {orders.length === 0 ? (
                <p>Vous n'avez pas encore passé de commande.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Numéro de commande</th>
                            <th>Date</th>
                            <th>Produit</th>
                            <th>Quantité</th>
                            <th>Prix</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => (
                            <tr key={index}>
                                <td>{order.orderId}</td>
                                <td>{new Date(order.created_at).toLocaleDateString()}</td>
                                <td>{order.productName}</td>
                                <td>{order.quantity}</td>
                                <td>{Number(order.price).toFixed(2)} €</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Orders;
