import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Cart = () => {
    const [cart, setCart] = useState([]);
    const [error, setError] = useState(null);
    const location = useLocation();
    const selectedClient = location.state?.client;

    useEffect(() => {
        if (!selectedClient) {
            setError('Veuillez sélectionner un client pour accéder au panier.');
            return;
        }

        // Récupère le token JWT depuis localStorage
        const token = localStorage.getItem('token');

        if (!token) {
            setError('Vous devez être connecté pour accéder à votre panier.');
            return;
        }

        // Récupère les produits du panier pour le client sélectionné
        fetch(`http://localhost:5000/api/cart/${selectedClient.id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Ajoute le token JWT
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Erreur lors de la récupération du panier');
                }
                return res.json();
            })
            .then((data) => setCart(data))
            .catch((err) => setError(err.message));
    }, [selectedClient]);

    const removeFromCart = (productId) => {
        const token = localStorage.getItem('token');

        fetch('http://localhost:5000/api/cart/delete', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId: selectedClient.id,
                productId,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setCart(cart.filter((item) => item.id !== productId));
                }
            });
    };

    const updateQuantity = (productId, newQuantity) => {
        if (newQuantity <= 0) {
            removeFromCart(productId);
            return;
        }

        const token = localStorage.getItem('token');

        fetch('http://localhost:5000/api/cart/update', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId: selectedClient.id,
                productId,
                quantity: newQuantity,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setCart(cart.map((item) =>
                        item.id === productId ? { ...item, quantity: newQuantity } : item
                    ));
                }
            })
            .catch((error) => console.error('Erreur lors de la mise à jour de la quantité :', error));
    };

    const validateOrder = () => {
        const token = localStorage.getItem('token');

        if (!token) {
            alert('Veuillez vous connecter pour valider la commande.');
            return;
        }

        fetch('http://localhost:5000/api/orders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                products: cart,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    alert(`Commande validée avec succès ! Numéro de commande : ${data.orderId}`);
                    setCart([]); // Vide le panier après validation
                } else {
                    alert('Erreur lors de la validation de la commande.');
                }
            })
            .catch((error) => console.error('Erreur lors de la validation de la commande :', error));
    };

    const total = cart.reduce((acc, item) => acc + item.quantity * Number(item.price), 0);

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <div>
            <h1>Panier de {selectedClient?.name || 'Aucun client sélectionné'}</h1>
            {cart.length === 0 ? (
                <p>Votre panier est vide</p>
            ) : (
                <div>
                    <ul>
                        {cart.map((item) => (
                            <li key={item.id}>
                                <img
                                    src={`http://localhost:5000${item.image}`}
                                    alt={item.name}
                                    style={{ width: '50px', marginRight: '10px' }}
                                />
                                {item.name} - {item.quantity} x {Number(item.price).toFixed(2)} €
                                <button onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
                                <button onClick={() => updateQuantity(item.id, item.quantity - 1)}>-</button>
                                <button onClick={() => removeFromCart(item.id)}>Supprimer</button>
                            </li>
                        ))}
                    </ul>
                    <h2>Total : {total.toFixed(2)} €</h2>
                    <button onClick={validateOrder} style={{ marginTop: '20px' }}>Valider la commande</button>
                </div>
            )}
        </div>
    );
};

export default Cart;
