import React, { useState, useEffect } from 'react';
import '../css/Admin.css';


const AdminClients = () => {
    const [clients, setClients] = useState([]);
    const [editingClient, setEditingClient] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const token = localStorage.getItem('token'); // Récupère le token JWT

    // Charger les clients depuis l'API
    useEffect(() => {
        fetch('http://localhost:5000/api/clients', {
            headers: {
                Authorization: `Bearer ${token}`, // Inclure le token JWT dans les requêtes
            },
        })
            .then((res) => res.json())
            .then((data) => setClients(data))
            .catch((err) => console.error('Erreur lors de la récupération des clients :', err));
    }, [token]);

    const startEditing = (client) => {
        setEditingClient(client.id);
        setName(client.name);
        setEmail(client.email);
        setPhone(client.phone);
        setAddress(client.address);
    };

    const updateClient = () => {
        fetch(`http://localhost:5000/api/clients/${editingClient}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name, email, phone, address }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setClients((prev) =>
                        prev.map((client) =>
                            client.id === editingClient ? { ...client, name, email, phone, address } : client
                        )
                    );
                    setEditingClient(null);
                    setName('');
                    setEmail('');
                    setPhone('');
                    setAddress('');
                } else {
                    alert('Erreur lors de la mise à jour du client');
                }
            })
            .catch((err) => console.error('Erreur lors de la mise à jour :', err));
    };

    return (
        <div className="container">
            <h2>Gestion des clients</h2>
            <ul>
                {clients.map((client) => (
                    <li key={client.id}>
                        {editingClient === client.id ? (
                            <div>
                                <input
                                    type="text"
                                    placeholder="Nom"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Téléphone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Adresse"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <button onClick={updateClient}>Sauvegarder</button>
                                <button onClick={() => setEditingClient(null)}>Annuler</button>
                            </div>
                        ) : (
                            <div>
                                {client.name} - {client.email} - {client.phone} - {client.address}
                                <button onClick={() => startEditing(client)}>Modifier</button>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );    
};

export default AdminClients;
