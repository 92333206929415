import React, { useState, useEffect } from 'react';

const Stats = () => {
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0); // Initialise à 0
    const [topProducts, setTopProducts] = useState([]);
    const [error, setError] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        // Récupère le nombre total de commandes
        fetch('http://localhost:5000/api/stats/orders', {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((data) => setTotalOrders(data.totalOrders || 0)) // Ajoute une valeur par défaut
            .catch(() => setError('Erreur lors du chargement des commandes'));

        // Récupère les revenus totaux
        fetch('http://localhost:5000/api/stats/revenue', {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((data) => setTotalRevenue(Number(data.totalRevenue) || 0)) // Convertit en nombre
            .catch(() => setError('Erreur lors du chargement des revenus'));

        // Récupère les produits les plus vendus
        fetch('http://localhost:5000/api/stats/top-products', {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((data) => setTopProducts(data))
            .catch(() => setError('Erreur lors du chargement des produits populaires'));
    }, [token]);

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <div>
            <h1>Statistiques</h1>
            <div>
                <h2>Total des commandes : {totalOrders}</h2>
                <h2>Revenu total : {totalRevenue.toFixed(2)} €</h2> {/* totalRevenue sera toujours un nombre */}
            </div>
            <div>
                <h2>Produits les plus vendus :</h2>
                <ul>
                    {topProducts.map((product, index) => (
                        <li key={index}>
                            {product.name} - {product.totalSold} unités
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Stats;
